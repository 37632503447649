.filters-section .sort-and-filter-gallery {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 480px) {
  .filters-section .sort-and-filter-gallery {
    flex-direction: column;
    gap: 1rem;
  }

  .filters-section .sort-and-filter-gallery .filter-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .filters-section .sort-and-filter-gallery .filter-container .popup-container.right {
    left: 0;
    right: auto;
  }

  .filters-section .sort-and-filter-gallery .filter-container .popup-container.w-16r {
    width: 10rem;
  }
}

.label-in-product-filter {
  flex: 1;
  display: flex;
  align-items: center;
}

.label-in-product-filter .checkbox {
  display: inline-block;
  border: 1px solid var(--blue);
  padding: 0;
  appearance: none;
  width: 1rem;
  border-radius: 0.25rem;
  height: 1rem;
  border-color: var(--black-l2);
}

.label-in-product-filter .checkbox:focus {
  box-shadow: var(--shadow-form-element-focus);
}

.label-in-product-filter .checkbox:checked {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: var(--blue);
}

.label-in-product-filter .label-span {
  white-space: nowrap;
  margin-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
