@import "basics.css";
@import "button.css";
@import "button2.css";
@import "cssVariablesTw.css";
@import "EditGroup.css";
@import "flex.css";
@import "grid.css";
@import "notification.css";
@import "tailwind-forms2.css";
@import "tree.css";


.app, .page-wrapper, * {
  box-sizing: border-box;
}

.app {
  min-height: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.cssexy-main {
  --red-l3: #fef1f3;
  --red-l2: #fbb9c2;
  --red-l1: #f65c72;
  --red: #e40c2b;
  --red-d1: #ac0920;
  --red-d2: #620512;
  --red-d3: #2a0208;

  --black-l6: #fcfcfd;
  --black-l4: #f0f0f5;
  --black-l3: #d8d8e5;
  --black-l2: #a9a9c6;
  --black-l1: #4c4c73;
  --black: #1d1d2c;
  --black-d1: #060609;
  --black-25: rgba(29, 29, 44, 0.25);
  --black-10: rgba(29, 29, 44, 0.1);

  --white: #fcfbf7;
  --white-d1: #f2eddb;
  --white-d2: #e7debe;
  --white-d3: #ddd0a1;
  --white-25: rgba(247, 244, 233, 0.25);
  --white-50: rgba(247, 244, 233, 0.5);
  --white-75: rgba(247, 244, 233, 0.75);

  --input-bg: rgb(249, 250, 251, 1);

  --blue-l4: #f0fafa;
  --blue-l3: #d2f0f1;
  --blue-l2: #b4e5e8;
  --blue-l1: #78d1d5;
  --blue: #3cbcc3;
  --blue-d1: #339fa5;
  --blue-d2: #2a8287;
  --blue-d3: #206569;

  --yellow-l3: #fef8f1;
  --yellow-l2: #fae8cd;
  --yellow-l1: #f4cf98;
  --yellow: #eba63f;
  --yellow-d1: #c77f15;
  --yellow-d2: #80520d;
  --yellow-d3: #392406;

  --green-l2: #f7fbf7;
  --green-l1: #b5dbb6;
  --green: #438945;
  --green-d1: #306131;
  --green-d2: #1c3a1d;

  --fs-xs: 0.75rem;
  --lh-xs: 1rem;
  --fs-s: 0.875rem;
  --lh-s: 1.25rem;
  --fs-base: 18px;
  --lh-base: 1.5rem;
  --fs-m: 1.125rem;
  --lh-m: 1.625;
  --fs-l: 1.5rem;
  --lh-l: 2rem;
  --fs-xl: 1.75rem;
  --lh-xl: 2.25rem;
  --fs-xxl: 2rem;
  --lh-xxl: 2.5rem;
  --fs-xxxl: 2.5rem;
  --lh-xxxl: 3rem;
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-bold: 700;
  --z-index-1: 1;
  --z-index-2: 5;
  --z-index-3: 99;
  --z-index-4: 1000;

  --border-radius-1: 4px;
  --border-radius-2: 10px;
  --border-radius-3: 50%;

  --border-default: 1px solid var(--color-outline-light);
  --color-outline-light: #00000033;
  --color-outline-dark: rgba(0, 0, 0, 0.4);

  --shadow-form-element: 0 0 3px 0 var(--black-l2), 0 0 2px -1px var(--black-l2);
  --shadow-form-element-hover: 0 0 6px 0 var(--black-l2);
  --shadow-form-element-focus: 0 0 0 4px var(--blue-l2);
  --shadow-form-element-focus-orange: 0 0 0 4px var(--yellow-l2);
  --shadow-form-element-focus-gray: 0 0 0 4px var(--black-l4);
  --shadow-input: inset 0 0 0 1px var(--black-l2);
  --shadow-input-disabled: inset 0 0 0 1px var(--black-l3);
  --shadow-input-no-inset: 0 0 0 1px var(--black-l2);
  --shadow-modal: 0 8px 25px var(--black-l3);
  --shadow-popup: 0 25px 50px -12px var(--black-l2);

  --shadow-tw-xs: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow-tw-sm: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-tw-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-tw-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-tw-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-tw-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --shadow-tw-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  --shadow-tw-outline: 0 0 0 3px rgba(66, 153, 225, 0.5);
  --shadow-tw-none: 0 0 #0000;

  --box-shadow-default: 0 2px 2px var(--black-l3),
    0 3px 1px -2px var(--black-l6), 0 1px 5px var(--black-l2);
  --box-shadow-hover: 0 3px 3px var(--black-l3), 0 1px 7px var(--black-l6),
    0 3px 1px -1px var(--black-l2);

  --breakpoint-xs: 576px;
  --breakpoint-s: 768px;
  --breakpoint-m: 992px;
  --breakpoint-l: 1200px;

  --bp-xs: 480px;
  --bp-s: 640px;
  --bp-m: 768px;
  --bp-l: 1024px;
  --bp-xl: 1280px;

  --space-20: 20px;
  --space-xl: 60px;
  --space-l: 40px;
  --space-m: 30px;
  --space-s: 20px;
  --space-xs: 10px;

  color: var(--black);
}

h1, h2, h3, h4 {
  margin-top: 0;
}

h1 {
  font-size: var(--fs-xxl);
  line-height: var(--lh-xxl);
  font-weight: var(--fw-regular);
  margin-bottom: 1rem;
}

h2 {
  font-size: var(--fs-xl);
  line-height: var(--lh-xl);
  font-weight: var(--fw-regular);
  margin-bottom: 0.75rem;
}

h3 {
  font-size: var(--fs-l);
  line-height: var(--lh-l);
  font-weight: var(--fw-regular);
  margin-bottom: 0.75rem;
}

h4 {
  font-size: var(--fs-m);
  line-height: var(--lh-m);
  font-weight: var(--fw-regular);
  margin-bottom: 0.75rem;
}
