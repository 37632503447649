.select-simple-native.label {
  display: block;
  font-size: var(--text-sm);
  line-height: var(--text-sm--line-height);
  color: var(--color-gray-900);
  font-weight: var(--font-weight-medium);
  width: 100%;
  max-width: 20rem;
}
.select-simple-native.label span {
  display: block;
  margin-bottom: 0.25rem;
}

.select-simple-native.label .select-box {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-items: center;
}

.select-simple-native.label .select-box select {
  appearance: none;
  grid-row-start: 1;
  grid-column-start: 1;
  padding: 0.375rem 2rem 0.375rem 0.75rem;
  width: 100%;
  border-radius: var(--radius-sm);
  outline-color: var(--black-l2);
  outline-offset: 0;
  font-size: var(--text-sm);
  line-height: var(--text-sm--line-height);
  background: none;
  box-shadow: none;
  border: none;
  min-width: auto;
}
.select-simple-native.label .select-box select.sm {
  padding: 0.25rem 1.5rem 0.25rem 0.5rem;
}

.select-simple-native.label .select-box select:focus {
  outline-offset: 1px;
  outline-width: 2px;
  outline-color: var(--black-l3);
}

.select-simple-native.label .select-box svg.icon {
  display: block;
  width: 1rem;
  height: 1rem;
  grid-row-start: 1;
  grid-column-start: 1;
  justify-self: end;
  align-self: center;
  margin-right: 0.5rem;
  fill: var(--color-gray-500);
}
