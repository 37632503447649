.colour-picker.form {
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--black);
}

.colour-picker.form div.group-title  {
  display: block;
  text-align: center;
  padding: 0.25rem 0.5rem;
  text-transform: uppercase;
  border-radius: var(--radius-sm);
}

.colour-picker.form .group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.colour-picker.form .group label {
  width: calc(25% - 0.75rem);
  flex: 1 1 auto;
}


.colour-picker.form .group input.simple-text-input  {
  display: inline-block;
  padding: 0.25rem;
  min-width: 0;

  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;

  outline: 1px solid var(--black-l2);
  outline-offset: 0;

  border: none;
  box-shadow: none;
}

.colour-picker.form.simple-text-input input:focus {
  outline-offset: 1px;
  box-shadow: 0 0 0 2px var(--black-l2);

}