.picker {
  position: relative;
}

.swatch {
  width: 2rem;
  height: 2rem;
  border: 0.4rem solid #fff;
  box-shadow: var(--shadow-input-no-inset);
  cursor: pointer;
  background-color: var(--black-l2);
  border-radius: 0.375rem;

}

.swatch.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.color-picker-popover {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  z-index: 100;
  min-width: 200px;
  padding-bottom: 1rem;
}


.color-picker-popover .wrapper {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background-color: white;
  padding: 1rem 1rem;
  border-radius: 0.375rem;
}

.color-picker-popover .wrapper .presets {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.375rem;
}

.color-picker-popover .wrapper .presets button {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.color-continuum {
  width: 100%;
  position: relative;
  height: 25px;
}
.color-continuum.hue {
  height: 50px;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.color-continuum.hue {
  height: 50px;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}


.color-continuum .color-indicator {
  position: absolute;
  cursor: grab;
  top: 50%;
  width: 16px;
  height: 16px;
  border: 4px solid #fff;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%);
  box-shadow: var(--shadow-form-element);
}

.color-continuum .color-indicator:hover {
  box-shadow: var(--shadow-form-element-hover);
}

.color-preview {
  width: 100%;
  height: 20px;
}

.color-preview-text {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}
