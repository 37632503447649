.notification {
  position: absolute;
  top: 100%;
  right: auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  padding: 1rem;
  margin-top: 0.5rem;
  min-width: 16rem;
  background-color: var(--blue);
  color: white;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  border-radius: 0.5rem;
  box-shadow: var(--shadow-modal);
  transition: top 0.5s;
}

.notification.right {
  right: 0;
  left: auto;
  transform: translateX(0);
}
.notification.left {
  left: 0;
  right: auto;
  transform: translateX(0);
}

.notification.warning {
  background-color: var(--yellow);
}

@media screen and (max-width: 480px) {
  .notification {
    min-width: 10rem;
  }
}
