.edit-item-group-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
}

.edit-item-group-wrapper:not(:last-child) {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--black-l4);
}

.add-or-remove-field-group-wrapper:not(:last-child) {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--blue);
  border-color: var(--black-l4);
}

.form-elements-wrapper {
  --shadow-button: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow-input: inset 0 0 0 1px var(--black-l1);
  --shadow-input-focus: 0 0 0 4px var(--blue);
}

.form-elements-wrapper .label {
  display: block;
  position: relative;
}

.form-elements-wrapper .label.row {
  display: flex;
  gap: 1rem;
}

.form-elements-wrapper .label.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.form-elements-wrapper .label span {
  display: block;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: var(--black);
}

.form-elements-wrapper .label span.blue {
  color: var(--blue);
}

.form-elements-wrapper .label.disabled span {
  color: var(--black-l2);
}

.form-elements-wrapper input,
.form-elements-wrapper select,
.form-elements-wrapper textarea {
  appearance: none;
  display: block;
  padding: 0.375rem 0.75rem;
  width: 100%;
  background-color: #fff;
  border-color: var(--black-l1);
  border-width: 0;
  border-radius: 0.375rem;
  outline: 1px solid rgba(60, 188, 195, 0);
  outline-offset: 2px;
  box-shadow: var(--shadow-input);
  font-size: 1rem;
  line-height: 1.5rem;
}

.form-elements-wrapper input:focus,
.form-elements-wrapper select:focus,
.form-elements-wrapper textarea:focus {
  box-shadow: var(--shadow-input-focus);
}

.form-elements-wrapper input:disabled,
.form-elements-wrapper select:disabled,
.form-elements-wrapper textarea:disabled {
  background-color: var(--black-l6);
  box-shadow: var(--shadow-input-disabled);
  color: var(--black-l2);
}

.form-elements-wrapper input:disabled svg,
.form-elements-wrapper select:disabled svg,
.form-elements-wrapper textarea:disabled svg {
  fill: rgba(235, 166, 63, 1);
}

.form-elements-wrapper input .icon-left-btn-right,
.form-elements-wrapper select .icon-left-btn-right,
.form-elements-wrapper textarea .icon-left-btn-right {
  padding: 1rem 3rem 1rem 2.5rem;
}

.form-elements-wrapper input.icon-in-input-left,
.form-elements-wrapper select.icon-in-input-left,
.form-elements-wrapper textarea.icon-in-input-left {
  padding-left: 2rem;
}

.form-elements-wrapper input[type="email"],
.form-elements-wrapper input[type="text"] {
  min-width: 10rem;
}

.form-elements-wrapper select {
  padding-right: 2.5rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%231D1D2C' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  background-position: calc(100% - 0.5rem) 50%;
  background-color: #fff;
  min-width: 6rem;
}

.form-elements-wrapper .checkbox {
  height: 1.2rem;
  width: 1.2rem;
  display: inline-block;
  padding: 0;
  margin: 0;
}

.form-elements-wrapper .checkbox:focus {
  box-shadow:
    0 0 0 0 #fff inset,
    0 0 0 2px #fff,
    0 0 0 4px var(--blue);
  border: 1px solid var(--blue);
  border-color: var(--black-l2);
}

.form-elements-wrapper .checkbox:checked {
  background: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-color: var(--blue);
}

.form-elements-wrapper .checkbox:checked:disabled {
  background-color: var(--black-l3);
}

.form-elements-wrapper .checkbox.disabled {
  span {
    color: var(--black-l2);
  }
}

.form-elements-wrapper .unit-box {
  position: relative;
  display: flex;
}

.form-elements-wrapper .unit-box .unit {
  position: absolute;
  top: -1.5rem;
  bottom: 0;
  right: 0;
}

.form-elements-wrapper .icon-left {
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0.5rem;
  bottom: 0;
}

.form-elements-wrapper .icon-left svg {
  width: 1rem;
  height: 1rem;
  fill: none;
  stroke: var(--black-l1);
}

.form-elements-wrapper .icon-right {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0.625rem;
}

.form-elements-wrapper .button {
  appearance: button;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  box-shadow: var(--shadow-button);
  border: 1px solid transparent;
  position: relative;
}

.form-elements-wrapper .button:focus {
  box-shadow: var(--shadow-form-element-focus);
}

.form-elements-wrapper .button svg {
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--black-l2);
}

.form-elements-wrapper .button.icon {
  padding: 0.5rem;
}

.form-elements-wrapper .button.icon svg {
  fill: #fff;
}

.form-elements-wrapper .button.blue {
  background-color: rgba(60, 188, 195, 1);
  color: rgba(255, 255, 255, 1);
}

.form-elements-wrapper .button.blue:hover {
  background-color: var(--blue-d1);
}

.form-elements-wrapper .button.blue-outline {
  background-color: #fff;
  color: var(--blue);
  border: 1px solid rgba(60, 188, 195, 1);
}

.form-elements-wrapper .button.blue-outline svg {
  fill: var(--blue);
}


.form-elements-wrapper .button.blue-outline:hover {
  background-color: var(--blue);
  color: #fff;
}

.form-elements-wrapper .button.blue-outline:hover svg {
  fill: #fff;
}


.form-elements-wrapper .button.orange {
  background-color: var(--yellow);
  color: #fff;
  border: 1px solid var(--yellow);
}

.form-elements-wrapper .button.orange:hover {
  background-color: var(--yellow-d1);
}
.form-elements-wrapper .button.orange:focus {
  box-shadow: var(--shadow-form-element-focus-orange);
}

.form-elements-wrapper .button.orange-outline {
  background-color: #fff;
  color: var(--yellow);
  border: 1px solid var(--yellow);
}
.form-elements-wrapper .button.orange-outline svg {
  fill: var(--yellow);
}


.form-elements-wrapper .button.orange-outline:hover {
  background-color: var(--yellow);
  color: #fff;
}
.form-elements-wrapper .button.orange-outline:hover svg {
  fill: #fff;
}
.form-elements-wrapper .button.orange-outline:focus {
  box-shadow: var(--shadow-form-element-focus-orange);
}

.form-elements-wrapper .button.gray-outline {
  background-color: #fff;
  color: var(--black-l1);
  border: 1px solid var(--black-l2);
}

.form-elements-wrapper .button.gray-outline:hover {
  background-color: var(--black-l4);
}
.form-elements-wrapper .button.gray-outline:focus {
  box-shadow: var(--shadow-form-element-focus-gray);
}

.form-elements-wrapper .button.round {
  padding: 0.5rem;
  border-radius: 100%;
}

.form-elements-wrapper .button.small {
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
}
.form-elements-wrapper .button.small.icon {
  padding: 0.25rem;
}


.form-elements-wrapper .button.disabled {
  cursor: default;
  background-color: var(--black-l4);
  color: var(--black-l3);
  border-color: var(--black-l3);
}

.form-elements-wrapper .button.disabled:hover {
  background-color: var(--black-l4);
  color: var(--black-l3);
  border-color: var(--black-l3);
}

.form-elements-wrapper .button.disabled svg {
  fill: var(--black-l2);
}
